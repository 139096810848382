import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Utilities } from '../class/utilities';

interface MkgLog {
  timestamp: number;
  caller: string;
  info?: string;
  text?: string;
  error?: string;
}

interface LogSerie {
  id: number;
  companyId: string,
  companyCNPJ: string,
  loggedUserId: string,
  loggedUserEmail: string,
  userRoleInCompany: number,
  logs: MkgLog[]
}

export enum LOG_REPORTER_KEYS {
  CASHIER = "cashier-logs",
  STOCK = "stock-logs",
  INVOICE = "mkg-logs"
}

type Series = Array<LogSerie>;

@Injectable({
  providedIn: 'root'
})
export class LogReporterService {
  private currentId: number;
  private currentSerie: LogSerie;
  readonly DAYS_OF_REPORT = 5; // after x days, the most old reports will be replaced by new reports

  constructor(
    dataService: DataService
  ) {
    this.currentId = Date.now();
    dataService.ready$.subscribe(([user, company]) => {
      let logs = []
      if (this.currentSerie) {
        logs = this.currentSerie.logs;
        logs.push({
          timestamp: Date.now(),
          info: `Mudou de "${this.currentSerie.companyCNPJ}" para "${company.cnpj}"`
        })
      }
      this.currentSerie = {
        id: this.currentId,
        companyId: company.id,
        companyCNPJ: company.cnpj,
        loggedUserEmail: user.email,
        loggedUserId: user.id,
        userRoleInCompany: user.getRoleIn(company),
        logs
      }
    });

    globalThis['downloadReport'] = this.downloadReport.bind(this);
    globalThis['clearReport'] = this.clearReport.bind(this);
  }

  public downloadReport(key: LOG_REPORTER_KEYS) {
    try {
      const content = localStorage.getItem(key)
      const blob = new Blob([content], { type: 'application/json;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${key}.json`;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao baixar relatório", error)
    }
  }

  public clearReport(key: LOG_REPORTER_KEYS){
    localStorage.removeItem(key);
  }

  errorLogger(caller: string, key: LOG_REPORTER_KEYS) {
    return (error: any) => {
      try {
        const log: MkgLog = {
          caller,
          timestamp: Date.now(),
          error: JSON.stringify({
            message: error.message,
            name: error.name,
            stack: error.stack,
            class: error.constructor?.name,
          })
        }
        this._appendLog(log, key)
      } catch (error) {
        console.error("Erro ao adicionar log de erro ao relatório", error)
      }
    }
  }

  textLogger(caller: string, key: LOG_REPORTER_KEYS) {
    return (text: string) => {
      try {
        const log: MkgLog = {
          caller,
          text,
          timestamp: Date.now()
        }
        this._appendLog(log, key)
      } catch (error) {
        console.error("Erro ao adicionar log de texto ao relatório", error)
      }
    }
  }

  private _appendLog(log: MkgLog, key: LOG_REPORTER_KEYS) {
    this.currentSerie.logs.push(log);
    this._saveCurrentSerie(key);
  }

  private _saveCurrentSerie(key: LOG_REPORTER_KEYS) {
    const allSeries = JSON.parse(localStorage.getItem(key) || "[]") as Series;
    const index = allSeries.findIndex(s => s.id === this.currentId);
    if (index != -1) {
      allSeries[index] = this.currentSerie;
    } else {
      allSeries.push(this.currentSerie);
    }

    const minDateStamp = Date.now() - (this.DAYS_OF_REPORT * Utilities.MS_DAY)
    const newSeries = allSeries.filter(s => s.id > minDateStamp)

    localStorage.setItem(key, JSON.stringify(newSeries));
  }

}
